import MakeRequest from './MakeRequest'

export default {
  RequestLoginCode (payload) {
    return MakeRequest(
      'post',
      '/login/code.json',
      payload,
      { needsNoAuth: true }
    ).then((resp) => {
      return resp.body
    })
  },

  LoginWithCode (payload) {
    return MakeRequest(
      'post',
      '/login/verify.json',
      payload,
      { needsNoAuth: true }
    ).then((resp) => {
      return resp.body
    })
  }
}
