import MakeRequest from './MakeRequest'

export default {
  Members () {
    return MakeRequest('get', '/mx/members.json').then((resp) => {
      return resp.body
    })
  },

  ConnectUrl () {
    return MakeRequest('get', '/mx/connect-url.json').then((resp) => {
      return resp.body
    })
  },

  MemberConnectUrl (guid) {
    return MakeRequest('get', `/mx/connect-url/${guid}.json`).then((resp) => {
      return resp.body
    })
  },

  SyncMember (guid) {
    return MakeRequest('post', `/mx/member/${guid}.json`).then((resp) => {
      return resp.body
    })
  },

  DeleteMember (guid) {
    return MakeRequest('delete', `/mx/member/${guid}.json`).then((resp) => {
      return resp.body
    })
  }
}
