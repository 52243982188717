import { useQuasar } from 'quasar'
import { computed } from 'vue'

import { useStoreSession } from '@/stores/session'

export default function () {
  const q$ = useQuasar()

  const session = useStoreSession()

  const darkModeActive = computed(() => {
    return q$.dark.isActive
  })

  const darkModeReset = () => {
    q$.dark.set(false)

    session.resetDarkMode()
  }
  const darkModeSet = () => {
    q$.dark.set(true)

    session.setDarkMode()
  }

  return {
    darkModeActive,
    darkModeReset,
    darkModeSet
  }
}
