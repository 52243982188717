const routes = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/Login.vue'),
        name: 'public.login'
      }
    ]
  },
  {
    path: '/',
    component: () => import('layouts/PublicLayoutFooterLogo.vue'),
    children: [
      {
        path: 'claim/nft',
        name: 'public.claim.nft',
        component: () => import('pages/public/ClaimNFT.vue'),
        meta: { noAuth: true, skipSessionCheck: true }
      },
      {
        path: 'claim/nft-batch',
        name: 'public.claim.nft-batch',
        component: () => import('pages/public/ClaimNFTBatch.vue'),
        meta: { noAuth: true, skipSessionCheck: true }
      },
      {
        path: 'claim/nft-template',
        name: 'public.claim.nft-template',
        component: () => import('pages/public/ClaimNFTemplate.vue'),
        meta: { noAuth: true, skipSessionCheck: true }
      }
    ]
  },
  {
    path: '/user',
    component: () => import('layouts/UserLayout.vue'),
    children: [
      {
        path: 'asset/:id',
        name: 'user.asset',
        component: () => import('pages/user/CollectionAsset.vue'),
        props: (route) => {
          return Object.assign({}, route.params, route.query)
        }
      },
      {
        path: 'dashboard',
        component: () => import('pages/Dashboard.vue'),
        name: 'user.dashboard'
      },
      {
        path: 'offers',
        component: () => import('pages/user/Offers.vue'),
        name: 'user.offers'
      },
      {
        path: 'profile',
        component: () => import('pages/user/Profile.vue'),
        name: 'user.profile'
      },
      {
        path: 'unclaimed',
        component: () => import('pages/user/CollectionAssetsUnclaimed.vue'),
        name: 'user.unclaimed'
      }
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue')
  }
]

export default routes
