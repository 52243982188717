import MakeRequest from './MakeRequest'

export default {
  // Get details of NFT transfer-by-email
  GetEmailTransferInvite (payload) {
    const url = '/email-transfer.json'
    const opts = { needsNoAuth: true }
    return MakeRequest('get', url, payload, opts).then((resp) => {
      return resp.body.data
    })
  },

  ClaimNFT (payload) {
    const url = '/claim-nft.json'
    const opts = { needsNoAuth: true }
    return MakeRequest('post', url, payload, opts).then((resp) => {
      return resp.body.data
    })
  },

  // Get details of NFT transfer-batch-by-email
  GetEmailTransferInviteBatch (payload) {
    const url = '/email-transfer-batch.json'
    const opts = { needsNoAuth: true }
    return MakeRequest('get', url, payload, opts).then((resp) => {
      return resp.body.data
    })
  },

  ClaimNFTBatch (payload) {
    const url = '/claim-nft-batch.json'
    const opts = { needsNoAuth: true }
    return MakeRequest('post', url, payload, opts).then((resp) => {
      return resp.body.data
    })
  },

  // Get details of NFTemplate
  GetNFTemplate (payload) {
    const url = '/template-transfer.json'
    const opts = { needsNoAuth: true }
    return MakeRequest('get', url, payload, opts).then((resp) => {
      return resp.body.data
    })
  },

  // Get payment-intent for NFTemplate with pricing
  GetNFTemplatePaymentIntent (payload) {
    const url = '/template-payment-intent.json'
    const opts = { needsNoAuth: true }
    return MakeRequest('post', url, payload, opts).then((resp) => {
      return resp.body.data
    })
  },

  // Claim NFTemplate
  ClaimNFTemplate (payload) {
    const url = '/claim-nft-template.json'
    const opts = { needsNoAuth: true }
    return MakeRequest('post', url, payload, opts).then((resp) => {
      return resp.body.data
    })
  }
}
