import { defineStore } from 'pinia'

export const useStoreSession = defineStore('session', {
  state: () => {
    return {
      darkMode: false,
      token: null,
      user: null
    }
  },

  getters: {
    isLoggedIn: (state) => {
      return state.token !== null
    }
  },

  actions: {
    login (user, token = null) {
      this.user = user

      if (token) {
        this.token = token
      }
    },

    logout () {
      this.token = null
      this.user = null
    },

    resetDarkMode () {
      this.darkMode = false
    },

    setDarkMode () {
      this.darkMode = true
    },

    updateToken (token) {
      this.token = token
    }
  },

  persist: {
    enabled: true,
    strategies: [
      {
        key: '__session',
        paths: ['darkMode', 'token', 'user'],
        storage: localStorage
      }
    ]
  },
  share: {
    // An array of fields that the plugin will ignore.
    omit: []
  }
})
