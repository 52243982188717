import axios from 'axios'
import { useStoreSession } from 'src/stores/session'

const SessionCheckMethod = 'get'
const SessionCheckUrl = '/wallet/me.json'

export default ({ app, store, router }) => {
  if (process.env.PROD) {
    axios.defaults.baseURL = JSON.parse(process.env.API_URL) + '/wallet'
  } else {
    axios.defaults.baseURL = '/wallet'
  }

  // Request interceptor - add authentication header as needed
  axios.interceptors.request.use((config) => {
    if (!config.url.match(/\.json$/)) {
      return config
    }

    const needsNoAuth = config.headers['X-PASSTHRU'] ?? false
    const session = useStoreSession()
    const authToken = session.token

    if (needsNoAuth === false && authToken) {
      config.headers.Authorization = 'Bearer ' + authToken
    }

    delete config.headers['X-PASSTHRU']
    return config
  })

  // Response interceptor - handle authentication errors; update tokens
  axios.interceptors.response.use(
    (response) => {
      const headers = response.headers
      const session = useStoreSession()

      if (typeof response.status !== 'undefined' && response.status === 401) {
        // store.dispatch('session/logout', null, { root: true })
        return response
      }

      // token refresh - update client session
      if (headers.authorization !== undefined && session.isLoggedIn) {
        session.updateToken(headers.authorization)
      }

      return response
    },

    /**
     * Global error handling.
     * Considering 401 responses as unauthenticated and
     * considering any errors on session-check/session-refresh request
     * as unauthenticated
     */
    (error) => {
      const isUnauthenticated = error.response?.status === 401 ||
        (error.config.url === SessionCheckUrl && error.config.method.toLowerCase() === SessionCheckMethod)
      if (isUnauthenticated) {
        const session = useStoreSession()
        session.logout()

        /*
        const currentRoute = {
          name: router.currentRoute.name,
          path: router.currentRoute.path,
          params: router.currentRoute.params
        }

        store.dispatch('session/setRedirectOnLogin', currentRoute, { root: true })
        */
        router.push({ name: 'public.login' })
      }

      return Promise.reject(error)
    }
  )
}
