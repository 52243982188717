import { boot } from 'quasar/wrappers'
import { createPinia, PiniaVuePlugin } from 'pinia'
import { PiniaSharedState } from 'pinia-shared-state'
import piniaPersist from 'pinia-plugin-persist'

export default boot(({ app }) => {
  const pinia = createPinia()

  // You can add Pinia plugins here
  // pinia.use(SomePiniaPlugin)
  pinia.use(piniaPersist)
  pinia.use(
    PiniaSharedState({
      // Enables the plugin for all stores. Defaults to true.
      enable: true,
      // If set to true this tab tries to immediately recover the shared state from another tab. Defaults to true.
      initialize: true,
      // Enforce a type. One of native, idb, localstorage or node. Defaults to native.
      type: 'native'
    })
  )

  app.use(pinia)
  app.use(PiniaVuePlugin)
})
