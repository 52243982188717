import { route } from 'quasar/wrappers'
import { useStoreSession } from 'src/stores/session'
import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router'
import routes from './routes'

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function (/* { store, ssrContext } */) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory)

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.MODE === 'ssr' ? void 0 : process.env.VUE_ROUTER_BASE)
  })

  Router.beforeEach((to, from, next) => {
    const session = useStoreSession()
    const isLoggedIn = session.isLoggedIn
    // const noAuth = (to.meta && to.meta.noAuth) || false

    if (to.name && to.name.startsWith('public.') && !to.name.startsWith('public.claim.') && isLoggedIn) {
      return next({ name: 'user.dashboard' })
    }

    if (!isLoggedIn && !to.name.startsWith('public.')) {
      // store.dispatch('session/setRedirectOnLogin', to, { root: true })
      return next({ name: 'public.login' })
    }

    next()
  })
  return Router
})
