import MakeRequest from './MakeRequest'

export default {
  GetUnclaimed () {
    return MakeRequest(
      'get',
      '/claims/pending.json',
      {}
    ).then((resp) => {
      return resp.body
    })
  },

  GetAssets (payload) {
    return MakeRequest(
      'get',
      '/assets.json',
      payload
    ).then((resp) => {
      return resp.body
    })
  },

  Profile () {
    return MakeRequest(
      'get',
      '/me.json',
      {}
    ).then((resp) => {
      return resp.body
    })
  },

  WalletAdd (payload) {
    return MakeRequest(
      'post',
      '/add.json',
      payload
    ).then((resp) => {
      return resp.body
    })
  },

  GetWalletAssets (payload) {
    return MakeRequest(
      'get',
      '/wallet-assets.json',
      payload
    ).then((resp) => {
      return resp.body
    })
  },

  GetCollectionAsset (id) {
    return MakeRequest(
      'get',
      `/asset/${id}.json`
    ).then((resp) => {
      return resp.body
    })
  },

  GetOffers (payload) {
    return MakeRequest(
      'get',
      '/offers.json',
      payload
    ).then((resp) => {
      return resp.body
    })
  },

  WalletDisconnect (id) {
    return MakeRequest(
      'delete',
      `/connected-wallet/${id}.json`
    ).then((resp) => {
      return resp.body
    })
  }
}
